// reset
* {
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
.displaynone {
  display: none;
}
