@use "../utils/variables";
@use "sass:color";
// Styles globaux
* {
  margin: 0;
  padding: 0;
  font-family: variables.$font-family;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-family: variables.$font-family-orbitron;
  font-size: 32px;
  font-weight: bold;
}

// Styles pour le dialog
dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); // Fond semi-transparent
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Assurez-vous que la modale est bien au-dessus des autres éléments
}

// Styles pour la section modale
.section-modal {
  background: variables.$color-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;

  &__h2 {
    margin: 0 20px 0 20px;
    display: flex;
    align-items: baseline;
    color: variables.$blue;
  }

  &__groupe-img__img {
    height: 225px;
    width: 225px;
    object-fit: cover;
    max-width: 100%;
    border-radius: 8px;
  }

  &__p {
    margin: 0 20px 0 20px;
    font-size: 16px;
    text-align: center;
  }

  &__groupe-btn {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;

    &__btn {
      gap: 10px;
      border-radius: 8px;
      text-decoration: none;
      border: none;
      width: 196px;
      height: 54px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 20px;
      color: variables.$color-light;
      background-color: variables.$blue;
      transition: background 0.3s;
      &:hover {
        background: color.adjust(variables.$blue, $lightness: -10%);
      }
      & > * {
        background-color: unset;
      }
    }
  }

  &__close {
    cursor: pointer;
    color: variables.$blue;
    position: absolute;
    top: 10px;
    right: 10px;

    &__i {
      font-size: 40px;
      right: 5px;
    }
  }
}
