.underline {
  border-bottom: solid 1px;
}
* {
  font-family: "inter", sans-serif;
  font-style: normal;
}

body {
  background-color: #202020;
  background-image: url("./../../Images/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 150px;
}
h2 {
  font-family: orbitron;
  font-size: 32px;
  font-weight: bold;
}
