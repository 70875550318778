@use "../utils/variables";
main {
  margin: 20px;
  @media (min-width: 768px) {
    // margin: 0 60px 90px 60px;
    margin: 0 55px 0 55px;
  }
  @media (min-width: 1024px) {
    margin: 0 120px 90px 120px;
  }
}
//a-propos
.a-propos {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  &__h2 {
    color: variables.$blue;
    margin: 15px 0 20px 37px;
  }
  &__contour {
    margin-top: 20px;
    margin-bottom: 60px;
    border: 3px solid variables.$blue;
    margin: 0 15px 85px 37px;
    &__p {
      padding: 20px;
      text-align: left;
      display: block;
      position: relative;
      top: 10px;
      right: 10px;
      background-color: variables.$color-light;
    }
  }
}

//mes projets
.mes-projets {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 20px;
  &__sort {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: variables.$color-light;
    width: 100%;
    &__button {
      border: none;
      cursor: pointer;
      color: variables.$color-light;
      font-size: 15px;
      background-color: variables.$blue;
      width: 170px;
      padding: 10px;
    }
  }
  &__h2 {
    margin-left: 37px;
    position: relative;
    color: variables.$blue;
  }
  &__groupe {
    justify-items: center;
    width: 100%;
    display: grid;
    gap: 75px;
    &__contour {
      height: fit-content;
      width: fit-content;
      border: 3px solid variables.$blue;
      display: inline-grid;
      @media (min-width: 1024px) {
        max-height: 310px;
        max-width: 310px;
      }
    }
    @media (min-width: 768px) {
      gap: 40px;
      margin: 0 20px 0 20px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(100px, auto);
      &__contour {
        margin: 0;
      }
    }
    @media (min-width: 1440px) {
      gap: 50px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(100px, auto);
    }
  }
}
/*@media (min-width: 1024px) {*/
/*  .mes-projets__groupe {*/
/*    gap: 50px;*/
/*    grid-template-columns: repeat(3, 1fr);*/
/*    grid-auto-rows: minmax(100px, auto);*/
/*  }*/
/*}*/

.figure {
  position: relative;
  margin: 0;
  top: 10px;
  right: 10px;
  transition: right 0.5s ease-out, top 0.5s ease-out;
  &:hover {
    top: 0px;
    right: 0px;
    transition: right 0.5s ease-out, top 0.5s ease-out;
  }
  &__figcaption {
    color: variables.$color-light;
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    bottom: 10px;
    left: 10px;
    &--black {
      color: black;
    }
  }
  &__img {
    cursor: pointer;
    display: block;
    position: relative;
    height: 270px;
    width: 270px;
    object-fit: cover;
  }
}

.mes-competences {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 125px;
  &__h2 {
    position: relative;
    margin-left: 37px;
    color: variables.$blue;
  }
  &__groupe {
    display: grid;
    grid-template-columns: repeat(3, 0.1fr);
    grid-auto-rows: minmax(100px, auto);
    justify-items: center;
    justify-content: center;
    gap: 25px;
    &__logo {
      display: inline-grid;
      height: 78px;
      width: 78px;
    }
    @media (min-width: 768px) {
      margin: 0 40px 80px 40px;
      flex-wrap: wrap;
      grid-template-columns: none;
      display: flex;
    }
  }
}

.mon-parcours {
  margin: 50px 15px 85px 37px;
  &__h2 {
    color: variables.$blue;
  }
  &__contour {
    margin-top: 20px;
    margin-bottom: 60px;
    border: 3px solid variables.$blue;
  }
}
.groupe-texte {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  right: 10px;
  gap: 10px;
  background-color: variables.$color-light;
  color: variables.$blue;
  padding: 25px;
  &__bloc {
    display: flex;
    flex-direction: column;
    gap: 7px;
    &__i {
      margin: 20px 20px 20px 0;
      font-size: 50px;
    }
    &__dates {
      font-size: 18px;
    }
    &__h3 {
      font-size: 20px;
      font-weight: bold;
    }
    &__lieu {
      font-size: 18px;
    }
    &__stage {
      font-size: 16px;
      font-weight: bold;
    }
    &__trait::after {
      background-color: variables.$blue;
      margin-top: 5px;
      width: 3px;
      height: 22px;
      content: "";
      display: block;
    }
    @media (min-width: 375px) {
      &__h3 {
        font-size: 24px;
      }
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 20px;
    &__bloc {
      width: 33%;
      justify-content: flex-end;
      &__trait {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        &::after {
          margin-left: 10px;
          width: 45px;
          height: 3px;
          content: "";
          margin-top: 12px;
        }
      }
    }
  }
}
