* {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

.displaynone {
  display: none;
}

.underline {
  border-bottom: solid 1px;
}

* {
  font-family: "inter", sans-serif;
  font-style: normal;
}

body {
  background-color: #202020;
  background-image: url("./../../Images/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 150px;
}

h2 {
  font-family: orbitron;
  font-size: 32px;
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-family: "Orbitron", sans-serif;
  font-size: 32px;
  font-weight: bold;
}

dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.section-modal {
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.section-modal__h2 {
  margin: 0 20px 0 20px;
  display: flex;
  align-items: baseline;
  color: #0099a3;
}
.section-modal__groupe-img__img {
  height: 225px;
  width: 225px;
  object-fit: cover;
  max-width: 100%;
  border-radius: 8px;
}
.section-modal__p {
  margin: 0 20px 0 20px;
  font-size: 16px;
  text-align: center;
}
.section-modal__groupe-btn {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.section-modal__groupe-btn__btn {
  gap: 10px;
  border-radius: 8px;
  text-decoration: none;
  border: none;
  width: 196px;
  height: 54px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  color: #d9d9d9;
  background-color: #0099a3;
  transition: background 0.3s;
}
.section-modal__groupe-btn__btn:hover {
  background: rgb(0, 105.1288343558, 112);
}
.section-modal__groupe-btn__btn > * {
  background-color: unset;
}
.section-modal__close {
  cursor: pointer;
  color: #0099a3;
  position: absolute;
  top: 10px;
  right: 10px;
}
.section-modal__close__i {
  font-size: 40px;
  right: 5px;
}

.header {
  color: #0099a3;
}
.header__cv {
  height: 36px;
  display: flex;
  position: absolute;
  margin: 22px 0 0 10px;
  gap: 10px;
}
.header__cv__img {
  font-size: 42px;
  font-weight: bold;
}
.header__cv__p {
  font-size: 36px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}
.header__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header__content__div {
  margin-top: 100px;
  margin-bottom: 60px;
  border-radius: 90%;
  border: 3px solid #0099a3;
}
.header__content__div__img {
  display: block;
  position: relative;
  top: 10px;
  right: 10px;
  border-radius: 90%;
  height: 220px;
  width: 220px;
}
@media (min-width: 1024px) {
  .header__content__div__img {
    height: 275px;
    width: 275px;
  }
}
@media (min-width: 1024px) {
  .header__content {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 115px;
    margin: 0 55px 90px 55px;
  }
  .header__cv {
    top: 30px;
    right: 30px;
    margin: unset;
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
}
.nav__burger {
  cursor: pointer;
  font-size: 40px;
  position: fixed;
  margin-top: 25px;
  margin-right: 10px;
  z-index: 1;
}
.nav__sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 2;
  background-color: #e8e8e8;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 217px;
  top: 0;
  right: -250px;
  transition: right 0.5s ease;
}
.nav__sidenav--active {
  right: 0;
}
@media (min-width: 768px) {
  .nav__sidenav {
    width: 270px;
    right: -270px;
    transition: right 0.5s ease;
  }
  .nav__sidenav--active {
    right: 0;
  }
}
@media (min-width: 1024px) {
  .nav__sidenav {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 37px;
    background-color: unset;
    height: unset;
    position: unset;
  }
}
.nav__sidenav__close {
  cursor: pointer;
  font-size: 42px;
  margin-right: 10px;
}
@media (min-width: 1024px) {
  .nav__sidenav__close {
    display: none;
  }
}
.nav__sidenav__ul {
  padding: 0 25px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  list-style-type: none;
}
@media (min-width: 768px) {
  .nav__sidenav__ul {
    padding: 0 25px 0 30px;
  }
}
@media (min-width: 1024px) {
  .nav__sidenav__ul {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}
.nav__sidenav__ul__li__a {
  font-family: "Orbitron", sans-serif;
  font-size: 24px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .nav {
    width: 100%;
    justify-content: center;
  }
  .nav__burger {
    display: none;
  }
}

.details {
  width: 311.61px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.details__text {
  display: flex;
  flex-direction: column;
}
.details__h1 {
  display: flex;
  align-items: baseline;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5px;
}
.details__h1__h1::after {
  margin-left: 8.7px;
  background-color: #0099a3;
  width: 22px;
  height: 3px;
  content: "";
  align-items: baseline;
  display: block;
}
.details__p {
  font-size: 23px;
}
@media (min-width: 1024px) {
  .details {
    width: unset;
    align-items: flex-start;
  }
  .details__h1 {
    font-size: 45px;
  }
  .details__text {
    margin-top: 200px;
  }
  .details__p {
    font-size: 29px;
  }
}

.github {
  margin-top: 16px;
  display: flex;
  gap: 12px;
}
.github__i {
  display: flex;
  font-size: 24px;
}
.github__p {
  display: flex;
  font-size: 20px;
  align-items: baseline;
}
.github__p::after {
  margin-left: 8.7px;
  background-color: #0099a3;
  width: 198px;
  height: 3px;
  content: "";
  align-items: baseline;
  display: block;
}
@media (min-width: 1024px) {
  .github__i {
    font-size: 29px;
  }
  .github__p {
    font-size: 29px;
  }
  .github__p::after {
    width: 245px;
  }
}

.header-icon {
  margin-top: 20px;
  width: 311.33px;
  display: flex;
  flex-direction: column-reverse;
  gap: 55px;
}
.header-icon__chevron {
  text-align: center;
  width: 100%;
}
.header-icon__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
}
.header-icon__logo {
  height: 35px;
}
@media (min-width: 1024px) {
  .header-icon {
    flex-direction: row;
    gap: 45px;
  }
  .header-icon__chevron {
    margin-top: 60px;
    margin-left: 10px;
  }
}

.footer {
  background-repeat: no-repeat;
  background-position: center 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 15px 15px 15px;
  color: #d9d9d9;
  flex-direction: column;
  gap: 10px;
}
.footer--404 {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin: 0;
}
.footer__button {
  padding: 18px 20px;
  width: 88%;
  width: 78%;
  gap: 4px;
  display: flex;
  justify-content: center;
  background-color: #0099a3;
}
@media (min-width: 768px) {
  .footer__button {
    width: 350px;
  }
}
.footer__button__p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.footer__button__img {
  height: 24px;
}
.footer__contact {
  margin-top: 20px;
  display: flex;
  gap: 6px;
}
.footer__contact__i {
  display: flex;
  align-items: center;
}
.footer__github {
  gap: 6px;
  display: flex;
  align-items: center;
}
.footer__github__p {
  font-size: 20px;
}
.footer__mentions-legales__p {
  font-size: 9px;
}

main {
  margin: 20px;
}
@media (min-width: 768px) {
  main {
    margin: 0 55px 0 55px;
  }
}
@media (min-width: 1024px) {
  main {
    margin: 0 120px 90px 120px;
  }
}

.a-propos {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.a-propos__h2 {
  color: #0099a3;
  margin: 15px 0 20px 37px;
}
.a-propos__contour {
  margin-top: 20px;
  margin-bottom: 60px;
  border: 3px solid #0099a3;
  margin: 0 15px 85px 37px;
}
.a-propos__contour__p {
  padding: 20px;
  text-align: left;
  display: block;
  position: relative;
  top: 10px;
  right: 10px;
  background-color: #d9d9d9;
}

.mes-projets {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 20px;
}
.mes-projets__sort {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  width: 100%;
}
.mes-projets__sort__button {
  border: none;
  cursor: pointer;
  color: #d9d9d9;
  font-size: 15px;
  background-color: #0099a3;
  width: 170px;
  padding: 10px;
}
.mes-projets__h2 {
  margin-left: 37px;
  position: relative;
  color: #0099a3;
}
.mes-projets__groupe {
  justify-items: center;
  width: 100%;
  display: grid;
  gap: 75px;
}
.mes-projets__groupe__contour {
  height: fit-content;
  width: fit-content;
  border: 3px solid #0099a3;
  display: inline-grid;
}
@media (min-width: 1024px) {
  .mes-projets__groupe__contour {
    max-height: 310px;
    max-width: 310px;
  }
}
@media (min-width: 768px) {
  .mes-projets__groupe {
    gap: 40px;
    margin: 0 20px 0 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
  .mes-projets__groupe__contour {
    margin: 0;
  }
}
@media (min-width: 1440px) {
  .mes-projets__groupe {
    gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}

/*@media (min-width: 1024px) {*/
/*  .mes-projets__groupe {*/
/*    gap: 50px;*/
/*    grid-template-columns: repeat(3, 1fr);*/
/*    grid-auto-rows: minmax(100px, auto);*/
/*  }*/
/*}*/
.figure {
  position: relative;
  margin: 0;
  top: 10px;
  right: 10px;
  transition: right 0.5s ease-out, top 0.5s ease-out;
}
.figure:hover {
  top: 0px;
  right: 0px;
  transition: right 0.5s ease-out, top 0.5s ease-out;
}
.figure__figcaption {
  color: #d9d9d9;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  bottom: 10px;
  left: 10px;
}
.figure__figcaption--black {
  color: black;
}
.figure__img {
  cursor: pointer;
  display: block;
  position: relative;
  height: 270px;
  width: 270px;
  object-fit: cover;
}

.mes-competences {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 125px;
}
.mes-competences__h2 {
  position: relative;
  margin-left: 37px;
  color: #0099a3;
}
.mes-competences__groupe {
  display: grid;
  grid-template-columns: repeat(3, 0.1fr);
  grid-auto-rows: minmax(100px, auto);
  justify-items: center;
  justify-content: center;
  gap: 25px;
}
.mes-competences__groupe__logo {
  display: inline-grid;
  height: 78px;
  width: 78px;
}
@media (min-width: 768px) {
  .mes-competences__groupe {
    margin: 0 40px 80px 40px;
    flex-wrap: wrap;
    grid-template-columns: none;
    display: flex;
  }
}

.mon-parcours {
  margin: 50px 15px 85px 37px;
}
.mon-parcours__h2 {
  color: #0099a3;
}
.mon-parcours__contour {
  margin-top: 20px;
  margin-bottom: 60px;
  border: 3px solid #0099a3;
}

.groupe-texte {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  right: 10px;
  gap: 10px;
  background-color: #d9d9d9;
  color: #0099a3;
  padding: 25px;
}
.groupe-texte__bloc {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.groupe-texte__bloc__i {
  margin: 20px 20px 20px 0;
  font-size: 50px;
}
.groupe-texte__bloc__dates {
  font-size: 18px;
}
.groupe-texte__bloc__h3 {
  font-size: 20px;
  font-weight: bold;
}
.groupe-texte__bloc__lieu {
  font-size: 18px;
}
.groupe-texte__bloc__stage {
  font-size: 16px;
  font-weight: bold;
}
.groupe-texte__bloc__trait::after {
  background-color: #0099a3;
  margin-top: 5px;
  width: 3px;
  height: 22px;
  content: "";
  display: block;
}
@media (min-width: 375px) {
  .groupe-texte__bloc__h3 {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .groupe-texte {
    flex-direction: row;
    gap: 20px;
  }
  .groupe-texte__bloc {
    width: 33%;
    justify-content: flex-end;
  }
  .groupe-texte__bloc__trait {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .groupe-texte__bloc__trait::after {
    margin-left: 10px;
    width: 45px;
    height: 3px;
    content: "";
    margin-top: 12px;
  }
}

.mention-legales {
  color: #d9d9d9;
  display: flex;
  gap: 30px;
  flex-direction: column;
}
.mention-legales__h1 {
  text-align: center;
  color: #0099a3;
  border-bottom: solid 2px #0099a3;
  margin-top: 80px;
}
.mention-legales span {
  color: #0099a3;
}
.mention-legales h2 {
  color: #0099a3;
  font-size: 24px;
}
@media (min-width: 768px) {
  .mention-legales {
    gap: 60px;
  }
}
@media (min-width: 1024px) {
  .mention-legales {
    gap: 90px;
  }
}

.proprietaire-du-site {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.proprietaire-du-site__h2 {
  font-size: 20px;
}

.hebergeur {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.hebergeur__groupe {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.hebergeur__h3 {
  color: #0099a3;
}

.CGU {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.prevention__h2 {
  font-size: 26px;
  font-family: inter;
}

.proprietaire-du-site {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.proprietaire-du-site__h2 {
  font-size: 20px;
}

.Page404 {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Page404__img {
  height: 99px;
  width: 198px;
  margin: 113px 0 113px 0;
}
.Page404__p404 {
  color: #0099a3;
  font-size: 150px;
  font-weight: bold;
}
.Page404__p {
  display: flex;
  flex-direction: column;
  color: #0099a3;
  text-align: center;
}
.Page404__link {
  color: #0099a3;
  margin: 50px 0 50px 0;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .Page404__link {
    margin: 113px 0 113px 0;
  }
  .Page404__img {
    height: 263px;
    width: 597px;
  }
  .Page404__p {
    flex-direction: row;
  }
}

